<template>
  <b-card>

    <question-add-content class="mt-2 pt-75" :isEdit="false" />
  </b-card>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner
} from 'bootstrap-vue'
import QuestionAddContent from './QuestionAddContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,

    QuestionAddContent,
  },
}
</script>
